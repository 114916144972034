import { BarChart } from "@mantine/charts"
import { Checkbox, Flex, NumberInput } from "@mantine/core"
import { useDebouncedCallback } from "@mantine/hooks"
import _ from "lodash"
import { useState } from "react"

import { Filters } from "@costory/types/filters"

import WaterfallChartTooltip from "@costory/front/components/charts/waterfallChartTooltip"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useWaterfallQuery } from "@costory/front/queries/explore"
import {
  COLOR_PALETTE,
  PayloadItem,
  truncateLabel,
} from "@costory/front/utils/charts"
import { formatNumber } from "@costory/front/utils/format"

type Props = {
  filters: Filters
  setThreshold?: (value: number | null) => void
  height?: number
  withLegend?: boolean
  drillDownInto?: (value: string) => void
  tooltipYPosition?: number
}

const getBarColor = (cost: number, index: number, size: number): string => {
  if (index === 0 || index === size - 1) return "primary.5"
  return cost > 0 ? "red.5" : "green.5"
}

export const WaterfallChart = ({
  filters,
  height,
  withLegend,
  drillDownInto,
  setThreshold,
  tooltipYPosition = 0,
}: Props) => {
  const waterfallQuery = useWaterfallQuery(filters)
  const [checked, setChecked] = useState(!!filters.threshold)
  const onChangeDebounced = useDebouncedCallback(
    (val) => setThreshold && setThreshold(val),
    800,
  )

  return (
    <QueryWrapper query={waterfallQuery}>
      {({ data }) => (
        <>
          <Flex gap={10} align="center" mb="lg" justify="right">
            {checked && (
              <NumberInput
                value={Number(filters.threshold)}
                onChange={(e) => onChangeDebounced(+e)}
              />
            )}
            {setThreshold && (
              <Checkbox
                label="Threshold"
                checked={checked}
                onChange={(event) => {
                  setChecked(event.currentTarget.checked),
                    !event.currentTarget.checked &&
                      setThreshold &&
                      setThreshold(null)
                }}
              />
            )}
          </Flex>
          <BarChart
            h={height ?? "100%"}
            pr="30px"
            data={_.filter(
              data.waterfallChartData.map((elem, index) => ({
                cost: elem.cost,
                name: truncateLabel(elem.name, 12),
                fullName: elem.name,
                color: getBarColor(
                  elem.cost,
                  index,
                  data.waterfallChartData.length,
                ),
              })),
              (elem) => Math.abs(elem.cost) >= 0.01,
            )}
            xAxisProps={{
              interval: 0,
              angle: 30,
              textAnchor: "start",
              style: {
                fontSize: "16px",
              },
            }}
            yAxisProps={{
              allowDataOverflow: true,
            }}
            dataKey="name"
            type="waterfall"
            tooltipProps={{
              position: {
                y: tooltipYPosition,
              },
              content: ({ payload }) => (
                <WaterfallChartTooltip
                  label={payload?.[0]?.payload?.fullName ?? ""}
                  payload={payload as PayloadItem[]}
                  tooltipData={data.dataGroupedByValueWithOthers}
                  currency={filters.currency}
                />
              ),
            }}
            valueFormatter={(value) =>
              formatNumber(value, "currency", 2, filters.currency)
            }
            series={[{ name: "cost", color: COLOR_PALETTE[0] }]}
            withLegend={withLegend}
            barChartProps={{
              onClick: (data) => {
                drillDownInto &&
                  data.activePayload &&
                  data.activePayload.length > 0 &&
                  drillDownInto(data.activePayload[0].payload.fullName)
              },
            }}
          />
        </>
      )}
    </QueryWrapper>
  )
}

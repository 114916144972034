import { useClerk } from "@clerk/clerk-react"
import { Stack, Group, Paper } from "@mantine/core"
import { useParams } from "react-router-dom"

import { SavedViewsResponses } from "@costory/types/endpoints/savedViews"

import { ChartContainer } from "@costory/front/components/ChartContainer"
import { Chart } from "@costory/front/components/charts/Chart"
import { ChartType } from "@costory/front/components/dashboard/constants"

type Props = {
  savedView?: SavedViewsResponses.SavedView
  chartTypeInput: ChartType
}

const Embed = ({ savedView, chartTypeInput }: Props) => {
  console.log("Embed", chartTypeInput)
  return (
    <ChartContainer
      savedView={savedView}
      redirectPage="explorer"
      key={savedView ? savedView.id : "no-view"}
      embed={true}
    >
      {({ filters }) => (
        // We removed drillDownInto to resolve the "'drillDownInto' is declared but its value is never read" error.
        <Stack>
          <Group flex={1} align="stretch" h={400}>
            <Paper flex={1} px={32} py={24}>
              <Stack h={400} justify="center">
                <Chart filters={filters} chartType={chartTypeInput} />
              </Stack>
            </Paper>
          </Group>
        </Stack>
      )}
    </ChartContainer>
  )
}

export const EmbedPage = () => {
  const clerk = useClerk()
  const { orgId, chartType } = useParams()
  clerk.setActive({ organization: orgId })
  return (
    <>
      <Embed chartTypeInput={(chartType as ChartType) || "TREND"} />
    </>
  )
}

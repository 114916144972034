import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from "react-router-dom"

import {
  AuthGuard,
  AuthGuardWithoutOrganization,
} from "@costory/front/components/auth/AuthGuard"
import { AppLayout } from "@costory/front/components/layout/AppLayout"
import { AlertCreationPage } from "@costory/front/pages/Alerts/AlertCreationPage"
import { AlertEditionPage } from "@costory/front/pages/Alerts/AlertEditionPage"
import { AlertsListPage } from "@costory/front/pages/Alerts/AlertListPage"
import { DashboardEditionPage } from "@costory/front/pages/Dashboards/DashboardEditionPage"
import { EmbedPage } from "@costory/front/pages/EmbedExplorer"
import { IntegrationPage } from "@costory/front/pages/Integration"
import { LabelsExplorerPage } from "@costory/front/pages/LabelsExplorer"
import { MetricsPage } from "@costory/front/pages/Metrics"
import { ReportsPage } from "@costory/front/pages/Reports/ReportsExplorerPage"
import { TagsPage } from "@costory/front/pages/Tags"
import { EditVirtualDimensionVersion } from "@costory/front/pages/VirtualDimensions/EditVirtualDimensionVersion"
import { JobExecutionsPage } from "@costory/front/pages/VirtualDimensions/JobExecutions"

import { DashboardCreationPage } from "./Dashboards/DashboardCreationPage"
import { DataHealthPage } from "./DataHealth"
import { DatasourcesPage } from "./Datasources"
import { ExplorerPage } from "./Explorer"
import { HomePage } from "./Home/HomePage"
import { InsightsPage } from "./Insights"
import { SavedViewsPage } from "./SavedViews"
import { SettingsPage } from "./Settings"
import { VirtualDimensionsPage } from "./VirtualDimensions"
import { EditVirtualDimensionPage } from "./VirtualDimensions/EditVirtualDimension"
import { NewVirtualDimensionPage } from "./VirtualDimensions/NewVirtualDimension"
import { WaterfallPage } from "./Waterfall"

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="org-selection" element={<AuthGuardWithoutOrganization />} />
      <Route element={<AuthGuard />}>
        <Route path="embed">
          <Route path=":orgId/:chartType" element={<EmbedPage />} />
        </Route>
        <Route element={<AppLayout />}>
          <Route index element={<Navigate to="/home" replace />} />
          <Route path="home" element={<HomePage />} />
          <Route path="reports" element={<ReportsPage />} />
          <Route path="dashboards">
            <Route path="new" element={<DashboardCreationPage />} />
            <Route path=":dashboardId" element={<DashboardEditionPage />} />
          </Route>
          <Route path="explorer">
            <Route index element={<ExplorerPage />} />
            <Route path="views/:viewId" element={<ExplorerPage />} />
          </Route>
          <Route path="waterfall">
            <Route index element={<WaterfallPage />} />
            <Route path="views/:viewId" element={<WaterfallPage />} />
          </Route>

          <Route path="insights" element={<InsightsPage />} />
          <Route path="virtual-dimensions">
            <Route index element={<VirtualDimensionsPage />} />
            <Route path="job-executions" element={<JobExecutionsPage />} />
            <Route path="new" element={<NewVirtualDimensionPage />} />
            <Route
              path=":virtualDimensionId"
              element={<EditVirtualDimensionPage />}
            />
            <Route
              path=":virtualDimensionId/draft/:draftId"
              element={<EditVirtualDimensionVersion />}
            />
          </Route>
          <Route path="data-health" element={<DataHealthPage />} />
          <Route path="datasources" element={<DatasourcesPage />} />
          <Route path="labels-explorer" element={<LabelsExplorerPage />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route path="metrics" element={<MetricsPage />} />
          <Route path="tags" element={<TagsPage />} />
          <Route path="saved-views" element={<SavedViewsPage />} />
          <Route path="alerts">
            <Route index element={<AlertsListPage />} />
            <Route path="new" element={<AlertCreationPage />} />
            <Route path=":alertId/edit" element={<AlertEditionPage />} />
          </Route>
          <Route path="integration" element={<IntegrationPage />} />
        </Route>
      </Route>
    </Route>,
  ),
)
